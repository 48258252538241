import 'babel-polyfill'

let cached;


export function getBranchen() {
    if(cached === undefined){
        cached = fetch(process.env.BACKEND_BASE_URL + '/api/branchen')
        .then(response => {
            if(response.ok){
                return response.json()
            }            
        })
    }
    if (cached !== undefined){
        cached.then(x => (cached = new Promise(resolve => resolve(x))))
    }        
    return cached;        
}