import './../css/main.sass'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/modal.js'
import 'slick-carousel/slick/slick.js'
import './partials/navbar.js'
import Gumshoe from 'gumshoejs/dist/gumshoe.js'
import '../../node_modules/babel-polyfill'
import { initMap } from './map'

import Vue from 'vue/dist/vue.esm'
import Slick from 'vue-slick';

import instantiateSearchBox from './components/searchbox.js'

let searchBox = instantiateSearchBox('#search-div')

let store = {
    debug: true,
    state: {
            defaultBackgroundImage: require('../assets/' + BACKGROUNDIMAGE_FALLBACK),
            defaultFilialeIcon: require('../assets/' + ICON_FALLBACK),
            currentCoupons: [],
            currentVeranstaltungen: []
    },
    setDataCurrentCoupons(dataObject){
        for(let i = 0; i < dataObject.length; i++){
            let parts = dataObject[i].valid_from.split('-')
            dataObject[i].valid_from = parts[2] +'.'+ parts[1] +'.'+ parts[0]
            parts = dataObject[i].valid_to.split('-')
            dataObject[i].valid_to = parts[2] +'.'+ parts[1] +'.'+ parts[0]
        }
        this.state.currentCoupons = dataObject
        this.state.currentCoupons.push()
    },
    setDataCurrentVeranstaltungen(dataObject) {
        let date = new Date()
        let currentDay = date.getDate();
        let currentMonth = `${date.getMonth() + 1}`.padStart(2, "0");
        // change the date to today's date if the beginning of the event is in the past but the end is in the future
        for (let i = 0; i < dataObject.length; i++) {
            let parts = dataObject[i].termin_datum_von.split('-')
            if (currentDay > parts[2] && currentMonth >= parts[1]) {
                dataObject[i].day = currentDay
            } else {
                dataObject[i].day = parts[2]
            }
            dataObject[i].month = this.getMonthName(parts[1])
            dataObject[i].termin_zeit_von = dataObject[i].termin_zeit_von != null ? dataObject[i].termin_zeit_von.slice(0, -3) : "";
            dataObject[i].termin_zeit_bis = dataObject[i].termin_zeit_bis != null ? dataObject[i].termin_zeit_bis.slice(0, -3) : "";
        }
        this.state.currentVeranstaltungen = dataObject
        this.state.currentVeranstaltungen.push()
    },
    getMonthName(index) {
        let mlist = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]
        return mlist[index - 1];
    }
}

let body = new Vue({
    el: '#index-main',
    components: {
        Slick
    },
    data: store.state,
    computed: {
        slickOfferOptions: function() {
            return {
                slidesToShow: 5,
                infinite: true,
                arrows: true,
                speed: 300,
                slidesToScroll: 1,
                responsive: [{
                        breakpoint: 1300,
                        settings: {
                            slidesToShow: 3,
                            infinite: false,
                            dots: false,
                        }
                    },
                    {
                        breakpoint: 780,
                        settings: {
                            slidesToShow: 2,
                            dots: false,
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1,
                            dots: false,
                        }
                    }
                ]
            }
        },
        slickEventOptions: function() {
            return {
                slidesToShow: 3,
                infinite: true,
                arrows: true,
                speed: 300,
                slidesToScroll: 1,
                responsive: [{
                        breakpoint: 1300,
                        settings: {
                            slidesToShow: 2,
                            infinite: false,
                            dots: false,
                            arrows: true
                        }
                    },
                    {
                        breakpoint: 780,
                        settings: {
                            slidesToShow: 1,
                            dots: false,
                            arrows: true
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1,
                            dots: false,
                            arrows: true
                        }
                    }
                ]
            }
        }
    },
    methods: {
        updateSlickSlider: function(item, self) {
            let currIndex = item.currentSlide()
            item.destroy()
            self.$nextTick(() => {
                item.create()
                item.goTo(currIndex, true)
            })
        }
    },
    watch: {
        //workaround to replace broken(empty) slick-slider with new instance after data fetch
        currentCoupons: function() {
            let self = this;
            this.updateSlickSlider(this.$refs.slick, self)
        }        
    }
})

function fetchCurrentCoupons() {
    
    fetch(process.env.BACKEND_BASE_URL + "/api/currentCoupons")
        .then(response => response.json())
        .then(json =>
            store.setDataCurrentCoupons(json)
        )
}
fetchCurrentCoupons()

function fetchCurrentVeranstaltungen() {
    // get date of 7 days later as ISOString 
    let maxDate = new Date()
    maxDate.setDate(maxDate.getDate() + 7);
    maxDate = maxDate.toISOString().slice(0,10);

    // fetch with optional Range (maxDate)
    fetch(process.env.BACKEND_BASE_URL + "/api/currentVeranstaltungen?max_date=" + maxDate)
        .then(response => response.json())
        .then(json =>
            store.setDataCurrentVeranstaltungen(json)
        )
}
fetchCurrentVeranstaltungen()

$(document).ready(function(){
    // Popup on index
    $('.close, .popup, #videoModalClose').unbind("click");
    $('.close, .popup, #videoModalClose').click(function() {
        closeModal();
        stopVideo();        
    });

    $("#videoModalMore").unbind("click");
    $("#videoModalMore").on("click", function(){
        closeModal();
        stopVideo();
        window.location = 'index.html#app'       
    });

    function closeModal(){
        $('.popup').removeClass("fadeInUp");
        $('.popup').fadeOut();
        $(".modal-dialog").addClass("fadeOutDown");
    }

    function stopVideo(){
        $(".yvideo").attr("src", $(".yvideo").attr("src"));
    }
});

window.onload = function() {
    $(document).ready(function() {
        initMap()

        // Navbar scrollspy
        let spy = new Gumshoe('#navbarNav a', {
            nested: true
        });

        // Asterisc
        $('.placeholder').click(function() {
            $(this).siblings('input').focus();
        });
        $('.form-control').focus(function() {
            $(this).siblings('.placeholder').hide();
        });
        $('.form-control').blur(function() {
            let $this = $(this);
            if ($this.val().length == 0)
                $(this).siblings('.placeholder').show();
        });
        $('.form-control').blur();

        // Toggle Show More
        $(".anzeigen").unbind("click").bind("click", function() {
            $("#toggle").slideToggle("fast", function() {
                if ($("#toggle").is(":visible")) {
                    $(".anzeigen > span").text("weniger anzeigen")
                    $(".anzeigen > img").css({
                        'transform': 'rotate(0deg'
                    })
                } else {
                    $(".anzeigen > span").text("mehr anzeigen")
                    $(".anzeigen > img").css({
                        'transform': 'rotate(180deg'
                    })
                }

            });
        });

        // Scroll from modal to app section
        $('#modalButton').click(function(event) {
            event.preventDefault();
            $('#appModal').modal('hide');
            $('html, body').animate({
                scrollTop: $("#app").offset().top
            }, 1000);
        });

        // Contact form

        // Email needs to be correct form
        let checkEMail = function() {
            let m = $("#mail").val()
            if (!m) return false;
            if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(m))
                return false;
            return true;
        }

        // Phone needs to be empty or correct form
        let checkPhone = function() {
            let p = $("#phone").val()
            if (!p) return true;
            if (!/^[0-9 \-]+$/.test(p))
                return false;
            return true;
        }

        $('#mail').change(function() {
            if (!checkEMail() && $("#mail").val())
                alert("Bitte eine korrekte EMail-Addresse eingeben.");
        });
        $('#phone').change(function() {
            if (!checkPhone())
                alert("Bitte eine korrekte Telefonnummer eingeben.");
        });

        $("#submit").click(function() {
            event.preventDefault()

            let n = $("#name").val()
            let m = $("#mail").val()
            let p = $("#phone").val()
            let msg = $("#msg").val();
            let body = "&body=" + $("#msg").val()

            if (!checkEMail()) {
                alert("Bitte eine korrekte EMail-Addresse eingeben.");
                return;
            }
            if (!checkPhone()) {
                alert("Bitte eine korrekte Telefonnummer eingeben.");
                return;
            }
            if (n == "") {
                alert("Bitte einen Namen angeben.");
                return;
            }
            if (msg == "") {
                alert("Bitte eine Nachricht angeben.");
                return;
            }

            if (p) {
                body += "%0D%0A%0D%0ASie erreichen mich unter: " + p
            }
            body += "%0A%0AMit freundlichen Grüßen%0A" + n
            let mailto = "mailto:support@ratingen-app.de?subject=Anfrage von " + n + body

            window.location.href = mailto;
        })
    });

    // Collapsible List
    let coll = document.getElementsByClassName("collapsible");
    let i;

    for (i = 0; i < coll.length; i++) {
        $(coll[i]).unbind("click").bind("click", function() {
            this.classList.toggle("collapsibleopen");
            let content = this.nextElementSibling;
            if (content.style.maxHeight) {
                content.style.maxHeight = null;
            } else {
                content.style.maxHeight = content.scrollHeight + "px";
            }
        });
    }

    // Back to top
    window.addEventListener('scroll', function() {
        if ($(this).scrollTop() >= 50) {
            $('#backToTop').fadeIn(200);
            $('.cookie-data').addClass('cookie-data-animation')
            $(".navbar-style-dark").find(".navbar").addClass("nav-solid");

        } else {
            $('#BackToTop').fadeOut(200);
            $('.cookie-data').removeClass('cookie-data-animation')
            $(".navbar-style-dark").find(".navbar").removeClass("nav-solid");
        }
    });



    $('#backToTop').click(function() {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
    });

    $('#cookie-bot-btn').unbind('click');
    $('#cookie-bot-btn').click(function() {
        $('#cookie-data-wrap').toggleClass('donot-display');
    });
};
