function setMarkers(map) {
    fetch(process.env.BACKEND_BASE_URL + "/api/parkhaeuser")
        .then(response => response.json())
        .then(obj => {
            const icons = makeMarkers();

            for (const item of obj) {
                const zIndex = item.ampel > 0 ? 2 : 1;
                const position = new google.maps.LatLng(item.lat, item.lon);

                const marker = new google.maps.Marker({
                    map: map,
                    title: item.name,
                    icon: icons.get(item.ampel),
                    position: position,
                    zIndex: zIndex
                });
                let capacityText = '';

                // Might need some more detailed description texts, depending on how the data from
                // the backend looks like.
                if (item.ampel == 0)
                    capacityText = item.capacity + ' Plätze';
                else {
                    capacityText = item.free + ' freie Plätze von ' + item.capacity + ' Plätzen';
                }

                const content = item.name + '<br><div style="color: #090909;">' + item.address + '<br>' + capacityText + '</div>';

                const infoPopup = new google.maps.InfoWindow()

                google.maps.event.addListener(marker, 'click', (function(marker, content, infoPopup) {
                    return function() {
                        infoPopup.setContent(content);
                        infoPopup.open(map, marker);
                    };
                })(marker, content, infoPopup));

            }
        })
}

function makeMarkers() {
    const map = new Map();
    const markerSize = new google.maps.Size(35, 35);

    // Icons in der Karte
    const icon_blue = {
        url: require('../assets/p_blue.png'),
        scaledSize: markerSize,
    };

    const icon_gruen = {
        url: require('../assets/p_green.png'),
        scaledSize: markerSize,
    };

    const icon_rot = {
        url: require('../assets/p_red.png'),
        scaledSize: markerSize,
    };

    const icon_gelb = {
        url: require('../assets/p_yellow.png'),
        scaledSize: markerSize,
    };

    const icon_parkhaus = {
        url: require('../assets/parkhaus.png'),
        scaledSize: markerSize,
    };

    // TODO:
    // The ids and what icons they correspond to come from the Ratingen backend.
    // Since this is subject to change for the new website, this is dummy data.
    // Note: since JSON returns everything as string, these can't be integer keys.
    map.set("0", icon_blue); // Generic parking spot, no info about capacity expected
    map.set("1", icon_rot);
    map.set("2", icon_gelb); // Lots of parking space left
    map.set("3", icon_gruen); // Some parking space left

    return map;
}

export function initMap() {
    let initialMapPosition = {
        lat: 51.297641,
        lng: 6.849640
    };

    let map = new google.maps.Map(document.getElementById('parkenmap'), {
        center: initialMapPosition,
        scrollwheel: false,
        disableDefaultUI: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scaleControl: true,
        styles: [{
            elementType: 'all',
            featureType: 'poi.business',
            stylers: [{
                visibility: 'off'
            }]
        }],
        zoomControl: true,
        zoom: 16
    });
    setMarkers(map)
} // end initMap