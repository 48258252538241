import Vue from 'vue/dist/vue.esm'
import '../../../node_modules/babel-polyfill'
import { getBranchen } from '../api/branchen-api.js'

export let storeBranches = {
    state: {            
        branches: []
    }
}

export default function instantiateSearchBox(divElement) {
    return new Vue({
        el: divElement,
        data: {
            privateState: {
                invalidSearch: false,
                searchTerm: "",                
                p_currentFilter: "",
                currentBranch: null,
            },
            sharedState: storeBranches.state
        },       
        computed: {
            currentFilter: {
                get: function () {
                    return this.privateState.p_currentFilter === "" ? "Filter" : this.privateState.p_currentFilter;
                },
                set: function (input) {
                    this.privateState.p_currentFilter = input
                }
            }
        },
        methods: {            
            startSearch: function() {
                let term = this.privateState.searchTerm;
                let filter = this.privateState.p_currentFilter;
                this.privateState.invalidSearch = false;
                if(term.length >= 3 || filter.length > 0){
                    if (term === "" && filter === ""){
                        return
                    } else {
                        window.location.href = "business-search.html?q=" + term + "&cat=" + filter;
                    }
                } else {
                    this.privateState.invalidSearch = true;
                }
            },
            async setBranches(){
                this.sharedState.branches = await getBranchen();
            },
            selectBranch: function(event, id){
                event.preventDefault();
                event.stopPropagation();
                
                for (let i = 0; i < this.sharedState.branches.length; i++) {
                    if(this.sharedState.branches[i].id == id){
                        this.privateState.currentBranch = this.sharedState.branches[i];
                        this.$nextTick(() => {
                            if(this.detectMobileQuery()){                    
                                $(divElement).find('.custom-dd-list-second').addClass("custom-dd-bring-to-front")
                                $(divElement).find('.custom-dd-list-first').addClass("disable-first-custom-dd")
                                $(divElement).find('.custom-dd-list-second').scrollTop(0);                 
                            }     
                        })                                           
                    }                                        
                }              
            },
            detectMobileQuery: function(){              
                let x = window.matchMedia("(max-width: 768px)")
                if(x.matches) {
                    return true;                    
                } else {
                    return false;
                }
            },
            onClassChange(classAttrValue){
                if(this.detectMobileQuery()){
                    // check if class show is applied index of not containing string is -1
                    if(~classAttrValue.indexOf('show')){
                        if(divElement == "#nav-search-div" || divElement == "#search-div"){
                            $(divElement).find("#nav-search-dropdown").addClass("custom-dd-mobile custom-dd-mobile-no-scroll");
                            $(divElement).find("#nav-search-submit").addClass("nav-search-submit-mobile");
                            this.$nextTick(() => {                                
                                $(divElement).find('.custom-dd-list-first').scrollTop(0);
                            })                         
                        } 
                    } else {
                        if(divElement == "#nav-search-div"  || divElement == "#search-div"){
                            $(divElement).find("#nav-search-dropdown").removeClass("custom-dd-mobile custom-dd-mobile-no-scroll");
                            $(divElement).find("#nav-search-submit").removeClass("nav-search-submit-mobile");
                            $(divElement).find('.custom-dd-list-second').removeClass("custom-dd-bring-to-front");
                            $(divElement).find('.custom-dd-list-first').removeClass("disable-first-custom-dd");
                        }
                    }
                }
            },
            resetSubBranchSelect(event){
                event.preventDefault();
                event.stopPropagation();
                $(divElement).find('.custom-dd-list-second').removeClass("custom-dd-bring-to-front")
                $(divElement).find('.custom-dd-list-first').removeClass("disable-first-custom-dd");
            }
            
        },

        mounted: function () {
            let that = this;
            // get all branches initialy
            this.setBranches();
            // function to trigger mobile hamburger nav
            let fun = function myFunction(x) {
                if (x.matches) { // If media query matches
                    $(divElement).find(".dropdown").addClass("navbar-nav")
                    if (divElement == "#nav-search-div"){                        
                        $(divElement).find(".dropdown").addClass("dropdown-mobile-scrollable")
                    }                       
                } else {
                    $(divElement).find(".dropdown").removeClass("navbar-nav")
                    if (divElement == "#nav-search-div"){
                        $(divElement).find(".dropdown").removeClass("dropdown-mobile-scrollable")
                    }
                                              
                }
            }


            let x = window.matchMedia("(max-width: 768px)")
            fun(x)
            x.addListener(fun)


            // obserever for the mobile view of search dropdown
            this.observer = new MutationObserver(mutations => {
                for (let m of mutations) {
                    let newValue = m.target.getAttribute(m.attributeName);
                    this.$nextTick(() => {
                        this.onClassChange(newValue, m.oldValue);
                    });
                }
            });

            this.observer.observe(this.$refs.custom_dd_wrap, {
                attributes: true,
                attributeOldValue: true,
                attributeFilter: ['class'],
            });

            $('#nav-search-dropdown').click(function(){
                $('#custom_dd_wrap').toggleClass('show');
            })

            $('#search-input').unbind("keyup");
            $('#search-input').on("keyup", function(event){
                if(event.key === "Enter"){
                    $('#navbar-search-button').click();
                }
            });

            $('#search-input-index').unbind("keyup");
            $('#search-input-index').on("keyup", function(event){
                if(event.key === "Enter"){
                    that.startSearch();
                }
            });
        },
        beforeDestroy(){
            this.observer.disconnect();
        }
        
    });
}

