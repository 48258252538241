import './../../css/main.sass'
import instantiateSearchBox from '../components/searchbox.js'



window.navClose = function() {
    $('#nav-dropdown').dropdown('toggle')
}

$(document).ready(function() {
    $("#nav-search-button").click(function() {
        let popup = $("#nav-search-div")
        popup.toggle()
        $("#nav-search-button").toggleClass("nav-search-button-active")
    });

    // Back to top and navbar background
    window.addEventListener('scroll', function() {
        if ($(this).scrollTop() >= 50) {
            $('#backToTop').fadeIn(200);
            $(".navbar-style-dark").find(".navbar").addClass("nav-solid");
            $(".navbar-style-light").find(".navbar").addClass("nav-solid");

        } else {
            $('#backToTop').fadeOut(200);
            $(".navbar-style-dark").find(".navbar").removeClass("nav-solid");
            $(".navbar-style-light").find(".navbar").removeClass("nav-solid");
        }
    });
})

$(function() {
    // ------------------------------------------------------- //
    // Multi Level dropdowns
    // ------------------------------------------------------ //
    $("ul.dropdown-menu [data-toggle='dropdown']").on("click", function(event) {
        event.preventDefault();
        event.stopPropagation();

        $(this).siblings().toggleClass("show");

        $(this).parent().siblings().find(".show").removeClass("show")

        if (!$(this).next().hasClass('show')) {
            $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }
        $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
            $('.dropdown-submenu .show').removeClass("show");
        });

    });
});

// detect the need of displaying the logout button
$(document).ready(function() {
    if(localStorage.getItem('ClientOAuth2Token') != null){
        document.getElementById('logout').classList.remove('donot-display');
    }
})

// detect click on login button 
let el = document.getElementById("login-dashboard-button");
if (el.addEventListener)
    el.addEventListener("click", setLogin, false);
else if (el.attachEvent)
    el.attachEvent('onclick', setLogin);

// detect click on logout button
let logout_el = document.getElementById("logout");
if (logout_el.addEventListener)
    logout_el.addEventListener("click", setLogout, false)
else if (logout_el.attachEvent)
    logout_el.attachEvent('onclick', setLogout);

// function to log in user-dashboard
function setLogin() {
    // redirect based on authentification Token
    if(localStorage.getItem('ClientOAuth2Token') == null) {
        window.location.href = "login.html";
    } else {
        window.location.href = "user-dashboard.html";
    }
}

// function to log out from user-dashboard
function setLogout(){
    // prevent default behavior 
    event.preventDefault();
    event.stopPropagation();

    // let cookie expire to get logged out    
    localStorage.removeItem('ClientOAuth2Token');
    
    // redirect to index.html (also resets all vue states)
    window.location.href = "index.html";
}

let headerSearchBox = instantiateSearchBox('#nav-search-div')